import { useState } from 'react'
import { useInterval } from 'ahooks'
import { Checkbox, Message, Modal } from '@nbit/arco'
import { t } from '@lingui/macro'
import LazyImage from '@/components/lazy-image'
import { advertTolink } from '@/helper/route/advert'
import { useUserStore } from '@/store/user'
import { UserAccountTypeEnum } from '@/constants/user'
import Icon from '@/components/icon'
import styles from './index.module.css'

function AdvertMultiPopup({ advertData, adsClose }) {
  const [visible, setVisible] = useState(true)
  const [checked, setChecked] = useState(false)
  const [active, setActive] = useState(0)
  const useStore = useUserStore()

  const imgList = advertData?.imageInfoList || []
  const delay = 5000 // 图片轮播时间

  useInterval(() => {
    const length = imgList?.length || 0
    if (length <= 1) return

    setActive(prevImage => (prevImage + 1) % length)
  }, delay)

  // 点击左侧列表图片
  const handleClick = index => {
    setActive(index)
  }

  const close = () => {
    setVisible(false)
    adsClose(checked)
  }

  const tolink = () => {
    if (useStore?.userInfo?.memberTag === UserAccountTypeEnum.demoUser) {
      Message.error(t`helper_route_advert_vejuhsgusz`)
      return
    }
    advertTolink(imgList, active)
    close()
  }

  return (
    <Modal
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      autoFocus={false}
      focusLock
      escToExit={false}
      closable={false}
      maskClosable
      footer={null}
      className={styles.advertswiper}
      afterClose={() => close()}
    >
      <div className="content flex justify-between">
        <div className="left">
          <div className="module">
            {imgList?.map((item, index) => {
              return (
                <div key={index} onClick={() => handleClick(index)}>
                  <LazyImage className="swiper-img" src={item?.imageUrl} />
                </div>
              )
            })}
          </div>
          <div className="number">
            {active + 1}/{imgList?.length}
          </div>
        </div>
        <div className="right">
          <div className="right-content">
            <LazyImage className="banner" src={imgList[active]?.imageUrl} />
          </div>
          <div className="flex justify-center" onClick={tolink}>
            <div className="right-btn">{t`features_advert_advert_swiper_index_uisukbtd59`}</div>
          </div>
          <div className="describe">
            <Checkbox checked={checked} onChange={checked => setChecked(checked)} />
            <span className="text">{t`features_advert_advert_swiper_index_aanzqfjsfl`}</span>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <Icon onClick={close} name={'icon_pop_close'} className="close-icon" />
      </div>
    </Modal>
  )
}

export default AdvertMultiPopup

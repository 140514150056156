import { useState } from 'react'
import { Message, Modal } from '@nbit/arco'
import LazyImage from '@/components/lazy-image'
import { advertTolink } from '@/helper/route/advert'
import { useUserStore } from '@/store/user'
import { t } from '@lingui/macro'
import { UserAccountTypeEnum } from '@/constants/user'
import Icon from '@/components/icon'
import styles from './index.module.css'

function AdvertSinglePopup({ advertData, adsClose }) {
  const [visible, setVisible] = useState(true)
  const useStore = useUserStore()

  const close = () => {
    setVisible(false)
    adsClose()
  }

  const tolink = () => {
    if (useStore?.userInfo?.memberTag === UserAccountTypeEnum.demoUser) {
      Message.error(t`helper_route_advert_vejuhsgusz`)
      return
    }
    advertTolink(advertData?.imageInfoList, 0)
    close()
  }

  return (
    <Modal
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      autoFocus={false}
      focusLock
      escToExit={false}
      closable={false}
      maskClosable
      footer={null}
      className={styles.advertswiper}
      afterClose={() => close()}
    >
      <div className="content" onClick={tolink}>
        <LazyImage className="img" src={advertData?.imageInfoList[0]?.imageUrl} />
      </div>
      <div className="flex justify-center">
        <Icon onClick={close} name={'icon_pop_close'} className="close-icon" />
      </div>
    </Modal>
  )
}

export default AdvertSinglePopup

import { useEffect, useState } from 'react'
import { usePageContext } from '@/hooks/use-page-context'
import { getPositionCdEnum } from '@/helper/common'
import { useUserStore } from '@/store/user'
import { cloneDeep } from 'lodash'
import { getAdvertTipsTime, setAdvertTipsTime } from '@/helper/cache/advert'
import { AdvertiseListData } from '@/typings/api/marketing/advertise'
import { useBaseAdvertise } from '@/store/marketing/advertise'
import { AdsStyleCdEnum } from '@/constants/marketing/advertise'
import AdvertMultiPopup from '../advert-multi-popup'
import AdvertSinglePopup from '../advert-single-popup'

function AdvertPopup() {
  const { advertiseData, fetchAdvertiseList } = useBaseAdvertise()
  const pageContext = usePageContext()
  const pathname = pageContext.path
  const useStore = useUserStore()
  const [advertList, setAdvertList] = useState<AdvertiseListData[]>([])

  let time = new Date().getDate()
  useEffect(() => {
    fetchAdvertiseList()
    useStore.isLogin && useStore.updatePreferenceAndUserInfoData()
  }, [])

  const close = (index, checked?: boolean) => {
    let list = cloneDeep(advertList)
    if (list[index]?.styleCd === AdsStyleCdEnum.multiPopup && checked) {
      setAdvertTipsTime(`${pathname}-${list[index]?.styleCd}-${list[index]?.id}-${useStore?.userInfo?.uid}`, time)
    }
    if (list[index]?.styleCd === AdsStyleCdEnum.singlePopup) {
      setAdvertTipsTime(`${pathname}-${list[index]?.styleCd}-${list[index]?.id}-${useStore?.userInfo?.uid}`, time)
    }
    if (!list[index + 1]) return
    list[index + 1].disable = true
    setAdvertList(list)
  }
  const getStorage = (item: AdvertiseListData) => {
    return getAdvertTipsTime(`${pathname}-${item?.styleCd}-${item?.id}-${useStore?.userInfo?.uid}`)
  }

  useEffect(() => {
    let list = cloneDeep(
      advertiseData?.advertiseList?.filter(
        item =>
          getPositionCdEnum(item?.positionCd).includes(pathname) &&
          (item?.styleCd === AdsStyleCdEnum.singlePopup || item?.styleCd === AdsStyleCdEnum.multiPopup)
      )
    )
    for (let i = 0; i < list.length; i += 1) {
      if (getStorage(list[i]) && Number(getStorage(list[i])) < time) {
        list[i].disable = true
        setAdvertTipsTime(`${pathname}-${list[i]?.styleCd}-${list[i]?.id}-${useStore?.userInfo?.uid}`, '0')
        break
      }
    }
    setAdvertList(list)
  }, [advertiseData?.advertiseList, pathname, useStore?.userInfo])
  return (
    <>
      {advertList.map((item, index) => {
        return (
          <div key={index}>
            {item.styleCd === AdsStyleCdEnum.multiPopup && item.disable && (
              <AdvertMultiPopup advertData={item} adsClose={checked => close(index, checked)} />
            )}
            {item.styleCd === AdsStyleCdEnum.singlePopup && item.disable && (
              <AdvertSinglePopup advertData={item} adsClose={() => close(index)} />
            )}
          </div>
        )
      })}
    </>
  )
}

export default AdvertPopup
